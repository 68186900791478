import React from "react";
import "../styles/globals.css";
import { UserProvider } from "@auth0/nextjs-auth0";
import TawkTo from "tawkto-react";
import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const canonicalUrl = (
    `https://www.coverdash.com` + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];
  return (
    <UserProvider>
      <DefaultSeo
        title="Coverdash: Small Business Insurance in a Dash"
        description="Coverage in minutes. Small business insurance in a Dash. Tailored Solutions. Instant Coverage. Big Savings."
        canonical={canonicalUrl}
        openGraph={{
          site_name: "Coverdash: Small Business Insurance in a Dash",
          title: "Coverdash: Small Business Insurance in a Dash",
          description:
            "Coverage in minutes. Small business insurance in a Dash. Tailored Solutions. Instant Coverage. Big Savings.",
          images: [
            {
              url: "https://i.ibb.co/V9B418h/coverdash-logo-bgwhite.jpg",
              width: 800,
              height: 600,
              alt: "Coverdash: Small Business Insurance | Freelancers | E-commerce Merchants | Small Business Owners",
            },
          ],
        }}
      />
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=62219b18-800f-4384-b866-b07a24a802aa"
      >
        {" "}
      </Script>
      <Component {...pageProps} />
    </UserProvider>
  );
}

export default MyApp;
